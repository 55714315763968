<template>
  <div id="pre-content">
    <div id="content" class="content-wrapper card">
      <b-container>
        <!-- Intro -->
        <!-- <div class="row">
          <div class="col-12">
            <p>Segue abaixo alguns cards utilitários para auxiliar a sua navegação no Dashboard.</p>
          </div>
        </div> -->
        <!-- Page content -->
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 pb-3">
            <totalizador />
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 pb-3">
            <assembleias-disponiveis-card />
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 pb-3">
            <pautas-card />
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import Totalizador from './cards/Totalizador'
import PautasCard from './cards/PautasCard'
import AssembleiasDisponiveisCard from './cards/AssembleiasDisponiveisCard'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: { Totalizador, PautasCard, AssembleiasDisponiveisCard  },
  computed: {
    ...mapGetters({
      pautas: 'pautas/getPautas'
    })
  }
};
</script>