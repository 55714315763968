<template>
  <div class="row content-header no-print">
    <div class="col-12">
      <h1>
        {{
        this.$route.meta.title
        ? this.$route.meta.title
        : 'Título'
        }}
      </h1>
      <span class="text-muted">
        {{
        this.$route.meta.subtitle
        ? this.$route.meta.subtitle
        : 'Subtítulo'
        }}
      </span>
      <hr class="w-100" />
    </div>
  </div>
</template>

<script>
export default {
  name: "base-header"
};
</script>