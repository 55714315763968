<template>
  <div class="w-100 bg-primary rounded p-3">
    <div class="row">
      <div class="col-12">
        <p class="text-white text-uppercase m-0">
          <strong>Dashboard</strong>
        </p>
        <hr class="w-100 mt-1"/>
      </div>
      <div class="col-md-4 col-lg-3 total-div mb-1">
        <div
          class="total-item"
          @click="goTo('pautas')"
        >
          <span class="m-0 text-white">Pautas</span>
          <span class="total-valor text-white">{{ totalPautas }}</span>
        </div>
      </div>
      <div class="col-md-4 col-lg-3 total-div mb-1">
        <div
          class="total-item"
          @click="goTo('assembleias')"
        >
          <span class="m-0 text-white">Assembleias</span>
          <span class="total-valor text-white">{{ totalAssembleias }}</span>
        </div>
      </div>
      <div class="col-md-4 col-lg-3 total-div mb-1">
        <div
          class="total-item"
          @click="goTo('ordens')"
        >
          <span class="m-0 text-white">Ordens</span>
          <span class="total-valor text-white">{{ totalOrdens }}</span>
        </div>
      </div>
      <div class="col-md-4 col-lg-3 total-div mb-1">
        <div
          class="total-item"
          @click="goTo('sindicatos')"
        >
          <span class="m-0 text-white">Sindicatos</span>
          <span class="total-valor text-white">{{ totalSindicatos }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'totalizador',
  computed: {
    ...mapGetters({
      totalPautas: 'pautas/getTotalPautas',
      totalAssembleias: 'assembleias/getTotalAssembleias',
      totalOrdens: 'ordens/getTotalOrdens',
      totalSindicatos: 'sindicatos/getTotalSindicatos',
    })
  },
  methods: {
    goTo(nome = 'intro') {
      this.$router.push({ name: nome })
    }
  }
}
</script>

<style scoped>
.total-item {
  background-color: #fff;
  /* background-color: #ffffff50; */
  padding: 10px;
  border-radius: 1rem;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.total-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.total-item:hover span {
  /* background-image: linear-gradient(#000, #000) !important;
  color: transparent !important;
  -webkit-background-clip: text;
  background-clip: text; */
  font-weight: 800;
  color: #000 !important;
}

.total-item span {
  color: red !important;
}
</style>

<style>
.total-item:hover a {
  text-decoration: underline !important;
}

.total-valor {
  font-size: 3rem;
  font-weight: 800;
}
</style>