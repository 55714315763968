// Dependencias
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { SINERGIA_LOCALSTORAGE_KEY_LOGIN } from '../lib/Authentication'

// vue router
Vue.use(VueRouter)

// Deinição das rotas
const routes = [{
    path: '/',
    name: 'intro',
    component: Home,
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: '',
      subtitle: ''
    }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: '',
      subtitle: ''
    }
  },
  {
    path: '/cedulas',
    name: 'cedulas',
    component: () => import('../views/Cedulas.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Lista de presença',
      subtitle: 'Segue aqui os resultados das votações de uma pauta.'
    }
  },
  {
    path: '/cedulas/*',
    name: 'cedulasParam',
    component: () => import('../views/Cedulas.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Lista de presença',
      subtitle: 'Segue aqui os resultados das votações de uma pauta.'
    }
  },
  {
    path: '/relatorioVotos',
    name: 'relatorioVotos',
    component: () => import('../views/RelatorioVotosAntigo.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Relatório de votações',
      subtitle: 'Visualize os resultados de votação de uma assembleia determinada.'
    }
  },
  {
    path: '/sugestoes',
    name: 'sugestoes',
    component: () => import('../views/Sugestoes.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Sugestões ao votar',
      subtitle: 'Lista de observações de usuários.'
    }
  },
  {
    path: '/pautas',
    name: 'pautas',
    component: () => import('../views/Pautas.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Pautas',
      subtitle: 'Lista de pautas que foram cadastradas para votação.'
    }
  },
  {
    path: '/ordens',
    name: 'ordens',
    component: () => import('../views/Ordens.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Ordens',
      subtitle: 'Seção de Ordens'
    }
  },
  {
    path: '/empresas',
    name: 'empresas',
    component: () => import('../views/Empresas.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Empresas',
      subtitle: 'Seção de Empresas'
    }
  },
  {
    path: '/sindicatos',
    name: 'sindicatos',
    component: () => import('../views/Sindicatos.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Sindicatos',
      subtitle: 'Seção de Sindicatos'
    }
  },
  {
    path: '/funcionarios',
    name: 'funcionarios',
    component: () => import('../views/Funcionarios.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Funcionários',
      subtitle: 'Seção de Funcionários'
    }
  },
  {
    path: '/assembleias',
    name: 'assembleias',
    component: () => import('../views/Assembleias.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Assembleias',
      subtitle: 'Seção de Assembleias'
    }
  },
  {
    path: '/assembleias/*',
    name: 'assembleiasParam',
    component: () => import('../views/Assembleias.vue'),
    meta: {
      requiresAuth: true,
      showSidebar: true,
      title: 'Assembleias',
      subtitle: 'Seção de Assembleias'
    }
  },
  {
    path: '/autenticacao',
    name: 'authentication',
    component: () => import('../views/Authentication.vue'),
    meta: {
      requiresAuth: false,
      showSidebar: false,
      hideHeader: true,
      title: 'Autenticação',
      subtitle: 'Autenticação Dashboard Sinergia'
    }
  },
  {
    path: '/*',
    name: 'pageNotFound',
    component: () => import('../views/PageNotFound.vue'),
    meta: {
      requiresAuth: false,
      showSidebar: false,
      hideHeader: true,
    }
  }
]

// Criação do router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Verificação pré rota
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {  // Requer autenticação
    if (!localStorage.getItem(SINERGIA_LOCALSTORAGE_KEY_LOGIN)) {
      next({ name: 'authentication' })
    } else {
      next()
    }
  } else {  // Não requer autenticação
    next()
  }
})

// Exportando router
export default router