<template>
  <!-- App main wrapper -->
  <div class="qrsconfig">
    <!-- App main content -->
    <main id="main" :class="[{ 'main-padding': this.$route.meta.showSidebar }, { 'header-gradiente': this.$route.meta.hideHeader }]">
      <!-- logo -->
      <navigation-header :class="{ 'd-none': this.$route.meta.hideHeader }" />
      <!-- Vou router wrapper -->
      <transition name="slide-left" mode="out-in">
        <router-view />
      </transition>
      <!-- Dashboard version -->
      <div id="version">
        <p class="m-0">Dashboard Sinergia v{{ APPLICATION_VERSION }}</p>
      </div>
    </main>
    <!-- App sidebar -->
    <sidebar />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  beforeMount() {
    this.token = process.env.AUTH_TOKEN;
    this.wsUrl = this.isProd ? process.env.WS_PROD : process.env.WS_DEV;
    this.initDashboard();
  },
  computed: {
    isProd() {
      return window.location.href.startsWith(process.env.PROD_URL);
    },
  },
  data: () => ({
    APPLICATION_VERSION: APPLICATION_VERSION,
    token: null,
    wsUrl: null,
  }),
  methods: {
    ...mapActions({
      setAuthentication: "auth/setAuthentication",
      loadPautas: "pautas/listarPautas",
      listarAssembleias: "assembleias/listarAssembleias",
      listarOrdens: "ordens/listarOrdens",
      listarSindicatos: "sindicatos/listarSindicatos",
    }),
    initDashboard() {
      this.setAuthentication({
        token: this.token,
        baseUrl: this.wsUrl,
      });
      this.loadPautas().then((response) => {
        if (![200, 201, 404].includes(response.status)) {
          this.$swal({
            type: response.status == 500 ? "error" : "warning",
            title: "Ops...",
            text: response.msg,
          });
        }
      });
      this.listarAssembleias().then((response) => {
        if (![200, 201, 404].includes(response.status)) {
          this.$swal({
            type: response.status == 500 ? "error" : "warning",
            title: "Ops...",
            text: response.msg,
          })
        }
      })
      this.listarOrdens().then((response) => {
        if (![200, 201, 404].includes(response.status)) {
          this.$swal({
            type: response.status == 500 ? "error" : "warning",
            title: "Ops...",
            text: response.msg,
          })
        }
      })
      this.listarSindicatos().then((response) => {
        if (![200, 201, 404].includes(response.status)) {
          this.$swal({
            type: response.status == 500 ? "error" : "warning",
            title: "Ops...",
            text: response.msg,
          })
        }
      })
    },
  },
};
</script>

<style lang="scss">
@import "./assets/styles/main";
</style>
