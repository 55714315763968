import Vue from 'vue';

// Initial state
const initialState = () => ({
  ordens: [],
  ordem: {}
});

// State object
const state = initialState();

// Getters
const getters = {
  getOrdens(state) {
    return state.ordens;
  },

  getTotalOrdens(state) {
    return state.ordens.length;
  },

  getOrdem(state) {
    return state.ordem
  },

  getOrdensToSelectInput(state) {
    return state.ordens.map(ordem => ({
      desc: ordem.titulo,
      value: ordem.id
    }));
  }
};

// Actions
const actions = {
  clearState({
    commit
  }) {
    commit("RESET");
  },

  listarOrdens({
    commit
  }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = '/v1/ordens';
      const resposta = await api.send(url, 'GET', null, undefined, false);

      if (resposta.status == 200) {
        commit('setOrdens', resposta.data.dados);
        resolve({
          status: resposta.status,
          msg: 'Lista de ordens recuperada',
          dados: resposta.data.dados
        });
      } else {
        commit('setOrdens', []);
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.status.mensagem || 'Ocorreu um erro desconhecido durante a sua requisição.'
        });
      }
    });
  },

  incluirOrdem({
    commit
  }, {
    titulo,
    conteudo,
  }) {
    const formatarData = data => data.toISOString().split('T')[0];

    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = '/v1/ordens';
      const resposta = await api.send(url, 'POST', {
        titulo,
        conteudo,
        dataCriacao: formatarData(new Date())
      });

      if (resposta.status == 201) {
        resolve({
          status: resposta.status,
          msg: 'Ordem registrada com sucesso'
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição'
        });
      }
    });
  },

  editarOrdem(_, {
    id,
    titulo,
    conteudo,
    dataCriacao
  }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = '/v1/ordens';
      const resposta = await api.send(url, 'PUT', {
        id,
        titulo,
        conteudo,
        dataCriacao
      });

      if (resposta.status == 200) {
        resolve({
          status: resposta.status,
          msg: 'Ordem editada com sucesso'
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante a sua requisição.'
        })
      }
    })
  },

  deletarOrdem(_, id) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/v1/ordens/${id}`;
      const resposta = await api.send(url, 'DELETE');

      if (resposta.status == 200) {
        resolve({
          status: resposta.status,
          msg: 'Ordem deletada com sucesso'
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.status.mensagem || 'Ocorreu um erro desconhecido durante sua requisição'
        });
      }
    })
  }
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },

  setOrdens(state, data) {
    state.ordens = data;
  },

  setOrdem(state, data) {
    state.ordem = data;
  }
};

// Module exports
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}