<template>
  <div id="sidebar" v-if="this.$route.meta.showSidebar">
    <div class="user-info">
      <div class="user-img">
        <router-link class="qrs-logo" :to="{ name: 'home' }">
          <img src="@/assets/images/logo.png" alt="QRsorteios logo" />
        </router-link>
      </div>
      <!-- Menu dropdown here -->
    </div>
    <div id="nav-menu" class="links">
      <navigation-icon icon="fas fa-home" destination="home" label="Home" />
      <navigation-icon
        icon="fas fa-vote-yea"
        destination="cedulas"
        label="Cedulas"
      />
      <navigation-icon
        icon="fas fa-chart-bar"
        destination="relatorioVotos"
        label="Relatório Votações"
      />
      <navigation-icon
        icon="fas fa-quote-left"
        destination="sugestoes"
        label="Observações"
      />
      <navigation-icon
        icon="fas fa-comments"
        destination="pautas"
        label="Pautas"
      />
      <navigation-icon
        icon="fas fa-clipboard-list"
        destination="ordens"
        label="Ordens"
      />
      <navigation-icon
        icon="fas fa-user-tie"
        destination="sindicatos"
        label="Sindicatos"
      />
      <navigation-icon
        icon="fas fa-building"
        destination="empresas"
        label="Empresas"
      />
      <navigation-icon
        icon="fas fa-users"
        destination="funcionarios"
        label="Funcionários"
      />
      <navigation-icon
        icon="fas fa-balance-scale"
        destination="assembleias"
        label="Assembleias"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "sidebar",
};
</script>
