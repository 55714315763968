import Vue from 'vue'

// Initial state
const initialState = () => ({
  assembleias: [],
  assembleiasDisponiveis: [],
  assembleia: {},
  empresas: [],
  sindicatos: [],
  votos: []
})

// State object
const state = initialState()

// Getters
const getters = {
  getAssembleias(state) {
    return state.assembleias
  },

  getTotalAssembleias(state) {
    return state.assembleias.length
  },

  getAssembleiasDisponiveis(state) {
    return state.assembleiasDisponiveis
  },

  getAssembleiasToSelectInput(state) {
    return state.assembleias.map(assembleia => ({
      desc: assembleia.nome,
      value: assembleia.id
    }));
  },

  getAssembleiaAtual() {
    return (state.assembleias && state.assembleias.length) ? state.assembleias[0] : null
  },

  getAssembleia(state) {
    return state.assembleia
  },

  getVotosAssembleiaAtual() {
    return (state.assembleias && state.assembleias.length) ? state.assembleias[0].votos.map(v => ({
      desc: v.titulo,
      value: v.id
    })) : null
  },

  getEmpresas() {
    return state.empresas.map(e => ({
      value: e.id,
      desc: e.nome
    }));
  },

  getSindicatosToSelectInput() {
    state.sindicatos.unshift({
      id: '',
      nome: 'Todos'
    });

    return state.sindicatos.map(sindicato => ({
      desc: sindicato.nome,
      value: sindicato.id
    }));
  },

  getVotacoesToSelectInput() {
    return state.votos.map(voto => ({
      desc: voto.titulo,
      value: voto.titulo
    }))
  }
}

// Actions
const actions = {
  clearState({
    commit
  }) {
    commit('RESET')
  },

  carregarAssembleias({ commit }, { empresa, pauta, sindicato, disponiveis = false }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient()
      const url = `/v1/assembleiasPorData`;
      const resposta = await api.send(url, 'POST', {
        empresa: empresa ? empresa : undefined,
        sindicato: sindicato ? sindicato : undefined,
        pauta: pauta ? pauta : undefined
      }, undefined, false);
      if ([200, 201].includes(resposta.status)) {
        disponiveis
          ? commit('setAssembleiasDisponiveis', resposta.data.dados.disponiveis)
          : commit('setAssembleias', resposta.data.dados.disponiveis)
        resolve({ status: 200, msg: 'Votos recuperados com sucesso' })
      } else {
        disponiveis
          ? commit('setAssembleiasDisponiveis', [])
          : commit('setAssembleias', [])
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        })
      }
    })
  },

  listarAssembleias({
    commit
  }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = '/v1/assembleias/listar';

      const resposta = await api.send(url, 'GET', null, undefined, false);

      if (resposta.status == 200) {
        commit('setAssembleias', resposta.data.dados);
        resolve({
          status: resposta.status,
          msg: 'Lista de pautas recuperada',
          dados: resposta.data.dados,
        });
      } else {
        commit('setAssembleias', []);
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.status.mensagem || 'Ocorreu um erro desconhecido durante sua requisição'
        });
      }
    })
  },

  obterAssembleia({
    commit
  }, {
    id
  }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/v1/assembleias/obter/${id}`;
      const resposta = await api.send(url, 'GET');

      if (resposta.status == 200) {
        commit('setAssembleia', resposta.data.dados);
        resolve({
          status: resposta.status,
          msg: 'Assembleia recuperada com sucesso',
          dados: resposta.data.dados
        });
      } else {
        commit('setAssembleia', {});
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição'
        });
      }
    });
  },

  incluirAssembleia({
    commit
  }, {
    nome,
    descricao,
    sindicatos,
    pauta,
    ordemDoDia,
    dataInicio,
    horaInicio,
    dataFinal,
    horaFinal,
    votos,
    candidatos
  }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = '/v1/assembleias';
      const resposta = await api.send(url, 'POST', {
        nome,
        descricao,
        sindicatos,
        pauta,
        ordemDoDia,
        dataInicio,
        horaInicio,
        dataFinal,
        horaFinal,
        votos,
        candidatos
      });

      if (resposta.status == 201) {
        resolve({
          status: resposta.status,
          msg: 'Assembleia registrada com sucesso',
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        });
      }
    });
  },

  editarAssembleia(_, {
    id,
    nome,
    descricao,
    sindicatos,
    pauta,
    ordemDoDia,
    dataInicio,
    horaInicio,
    dataFinal,
    horaFinal,
    votos,
    candidatos
  }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/v1/assembleias`;
      const resposta = await api.send(url, 'PUT', {
        id,
        nome,
        descricao,
        sindicatos,
        pauta,
        ordemDoDia,
        dataInicio,
        horaInicio,
        dataFinal,
        horaFinal,
        votos,
        candidatos
      });

      if (resposta.status == 200) {
        resolve({
          status: resposta.status,
          msg: 'Assembleia editada com sucesso',
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição'
        })
      }
    })
  },

  deletarAssembleia(_, idAssembleia) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/v1/assembleias/${idAssembleia}`;
      const resposta = await api.send(url, 'DELETE');

      if (resposta.status == 200) {
        resolve({
          status: resposta.status,
          msg: 'Assembleia deletada com sucesso',
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição'
        });
      }
    });
  },

  listarEmpresasPorAssempleia({
    commit
  }, {
    id
  }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient()
      const url = '/v1/empresasPorAssembleia'
      const resposta = await api.send(url, 'POST', {
        assembleia: id
      }, undefined, false)
      if ([200, 201].includes(resposta.status)) {
        commit('setEmpresas', resposta.data.dados.empresas)
        resolve({
          status: 200,
          msg: 'Empresas recuperadas com sucesso'
        })
      } else {
        commit('setEmpresas', [])
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        })
      }
    })
  },

  listarSindicatosEVotosPorAssembleia({
    commit
  }, {
    id
  }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/v1/assembleias/obter/${id}`;
      const resposta = await api.send(url, 'GET');

      if (resposta.status == 200) {
        commit('setSindicatos', resposta.data.dados.sindicatos);
        commit('setVotos', resposta.data.dados.votos);
        resolve({
          status: resposta.status,
          msg: 'Sindicatos recuperados com sucesso'
        });
      } else {
        commit('setSindicatos', []);
        commit('setVotos', []);
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição'
        });
      }
    });
  }
}

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },

  setAssembleia(state, data) {
    state.assembleia = data;
  },

  setAssembleias(state, data) {
    state.assembleias = data
  },

  setAssembleiasDisponiveis(state, data) {
    state.assembleiasDisponiveis = data
  },

  setEmpresas(state, data) {
    state.empresas = data
  },

  setSindicatos(state, data) {
    state.sindicatos = data;
  },

  setVotos(state, data) {
    state.votos = data
  }
}

// Module exports
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}