import Vue from 'vue';

// Inital State
const initialState = () => ({
  empresas: [],
  empresa: {}
});

// State object
const state = initialState();

// Getters
const getters = {
  getEmpresas(state) {
    return state.empresas;
  },

  getEmpresasToSelectInput(state) {
    return state.empresas.map(empresa => ({
      desc: empresa.nome,
      value: empresa.id
    }));
  },

  getEmpresa(state) {
    return state.empresa;
  },
};

// Actions
const actions = {
  clearState({
    commit
  }) {
    commit('RESET');
  },
  
  // 
  listarEmpresasPorAssembleia({
    commit
  }, {
    id
  }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient()
      const url = '/v1/empresasPorAssembleia'
      const resposta = await api.send(url, 'POST', {
        assembleia: id
      }, undefined, false)
      if ([200, 201].includes(resposta.status)) {
        commit('setEmpresas', resposta.data.dados.empresas)
        resolve({
          status: 200,
          msg: 'Empresas recuperadas com sucesso'
        })
      } else {
        commit('setEmpresas', [])
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        })
      }
    })
  },
  // 

  listarEmpresas({
    commit
  }, idSindicato) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = '/v1/empresas/listar';
      const resposta = await api.send(url, 'POST', idSindicato ? {
        sindicato: idSindicato
      } : null, undefined, false);

      if (resposta.status == 200) {
        commit('setEmpresas', resposta.data.dados.empresas);
        resolve({
          status: resposta.status,
          msg: 'Lista de empresas recuperadas',
          dados: resposta.data.dados
        });
      } else {
        commit('setEmpresas', []);
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.status.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        });
      }
    })
  },

  incluirEmpresa({
    commit
  }, {
    nome,
    locaisTrabalho,
    idSindicato,
    ativa
  }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/v1/empresas`;
      const resposta = await api.send(url, 'POST', {
        nome,
        locaisTrabalho,
        idSindicato,
        ativa
      });

      if (resposta.status === 201) {
        resolve({
          status: resposta.status,
          msg: 'Empresa registrada com sucesso',
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        });
      }
    });
  },

  editarEmpresa(_, {
    id,
    nome,
    locaisTrabalho,
    idSindicato,
    ativa
  }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = '/v1/empresas';
      const resposta = await api.send(url, 'PUT', {
        id,
        nome,
        locaisTrabalho,
        idSindicato,
        ativa
      });

      if (resposta.status === 200) {
        resolve({
          status: resposta.status,
          msg: 'Empresa editada com sucesso'
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição'
        });
      }
    });
  },

  deletarEmpresa(_, {
    idSindicato,
    idEmpresa
  }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/v1/empresas/${idSindicato}/${idEmpresa}`;

      const resposta = await api.send(url, 'DELETE');

      if (resposta.status == 200) {
        resolve({
          status: resposta.status,
          msg: 'Empresa deletada com sucesso'
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição'
        });
      }
    })
  }

};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },

  setEmpresas(state, data) {
    state.empresas = data;
  },

  setEmpresa(state, data) {
    state.empresa = data;
  }

};

// Module exports
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}