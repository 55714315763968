import Vue from "vue";

// Initial state
const initialState = () => ({
  pautas: [],
  pauta: {},
});

// State object
const state = initialState();

// Getters
const getters = {
  getPautas(state) {
    return state.pautas;
  },

  getPauta(state) {
    return state.pauta;
  },

  getPautasToSelectInput(state) {
    return state.pautas.map(pauta => ({
      desc: pauta.nome,
      value: pauta.id
    }))
  },

  getTotalPautas(state) {
    return state.pautas.length
  },
};

// Actions
const actions = {
  clearState({
    commit
  }) {
    commit("RESET");
  },

  listarPautas({
    commit
  }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient();
      const url = `/v1/pautas`;
      const resposta = await api.send(url, "GET", null, undefined, false);

      if (resposta.status == 200) {
        commit("setPautas", resposta.data.dados);
        resolve({
          status: resposta.status,
          msg: "Lista de pautas recuperada",
          dados: resposta.data.dados,
        });
      } else {
        commit("setPautas", []);
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.status.mensagem ||
            "Ocorreu um erro desconhecido durante sua requisição.",
        });
      }
    });
  },

  incluirPauta({
    commit
  }, {
    nome,
    descricao
  }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient();
      const url = "/v1/pautas";
      const resposta = await api.send(url, "POST", {
        nome,
        descricao,
      });

      if (resposta.status === 201) {
        resolve({
          status: resposta.status,
          msg: "Pauta registrada com sucesso",
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem ||
            "Ocorreu um erro desconhecido durante sua requisição.",
        });
      }
    });
  },

  obterPauta({
    commit
  }, {
    id
  }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient();
      const url = `v1/pautas/${id}`;

      const resposta = await api.send(url, "GET");

      if (resposta.status == 200) {
        commit("setPauta", resposta.data.dados);
        resolve({
          status: resposta.status,
          msg: "Pauta recuperada",
          dados: resposta.data.dados,
        });
      } else {
        commit("setPauta", {});
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem ||
            "Ocorreu um erro desconhecido durante sua requisição",
        });
      }
    });
  },

  editarPauta(_, {
    id,
    nome,
    descricao
  }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = '/v1/pautas';
      const resposta = await api.send(url, 'PUT', {
        id,
        nome,
        descricao
      });

      if (resposta.status === 200) {
        resolve({
          status: resposta.status,
          msg: 'Pauta editada com sucesso'
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição'
        });
      }

    });
  },

  deletarPauta(_, id) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient();
      const url = `/v1/pautas/${id}`;
      const resposta = await api.send(url, "DELETE");

      if (resposta.status == 200) {
        resolve({
          status: resposta.status,
          msg: "Pauta deletada com sucesso",
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem ||
            "Ocorreu um erro desconhecido durante sua requisição",
        });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  setPautas(state, data) {
    state.pautas = data;
  },
  setPauta(state, data) {
    state.pauta = data;
  },
};

// Module exports
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};