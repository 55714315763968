import Vue from 'vue'

// Estado inicial
const initialState = () => ({
  cedulas: [],
  carregando: false
})

// Objeto de estado
const state = initialState()

// Getters
const getters = {
  getCedulas() {
    return state.cedulas
  },
  estaCarregando() {
    return state.carregando
  }
}

// Ações
const actions = {
  clearState({ commit }) { commit('RESET') },
  fetchCedulas({ commit }, { idPauta, sindicato, empresa }) {
		return new Promise(async (resolve) => {
      commit('setCarregando', true)
			const api = new Vue.prototype.$httpClient()
			const url = `/v1/cedulas/filtrar`
			const response = await api.send(url, 'POST', {
        idPauta: idPauta ? idPauta : undefined,
        empresa: empresa ? empresa : undefined,
        sindicato: sindicato ? sindicato : undefined
      }, undefined, false);
      commit('setCarregando', false)
			if (response.status == 200) {
				commit('setCedulas', response.data.dados)
				resolve({
					status: 200,
					msg: 'Lista de cedulas recuperadas.'
				})
			} else {
				commit('setCedulas', [])
				resolve({
					status: response.status,
					msg: response.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
				})
			}
		})
	}
}

// Mutações
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    });
  },
  setCedulas(state, data) {
    state.cedulas = data
  },
  setCarregando(state, data) {
    state.carregando = data
  }
}

// Exoprtanto módulo
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}