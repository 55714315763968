import BaseButton from '../components/theme/BaseButton'
import BaseCard from '../components/theme/BaseCard'
import BaseDropdown from '../components/theme/BaseDropdown'
import BaseHeader from '../components/theme/BaseHeader'
import BaseInput from '../components/theme/BaseInput'
import BaseList from '../components/theme/BaseList'
import BaseModal from '../components/theme/BaseModal'
import BasePagination from '../components/theme/BasePagination'
import BaseSelect from '../components/theme/BaseSelect'
import BaseTextArea from '../components/theme/BaseTextArea'
import Sidebar from '../components/layout/template/Sidebar'
import NavigationHeader from '../components/layout/template/NavigationHeader'
import NavigationIcon from '../components/layout/template/NavigationIcon'

export default {
  install (Vue) {
    Vue.component(BaseButton.name, BaseButton)
    Vue.component(BaseCard.name, BaseCard)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component(BaseHeader.name, BaseHeader)
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseList.name, BaseList)
    Vue.component(BaseModal.name, BaseModal)
    Vue.component(BasePagination.name, BasePagination)
    Vue.component(BaseSelect.name, BaseSelect)
    Vue.component(BaseTextArea.name, BaseTextArea)
    Vue.component(Sidebar.name, Sidebar)
    Vue.component(NavigationIcon.name, NavigationIcon)
    Vue.component(NavigationHeader.name, NavigationHeader)
  }
}
