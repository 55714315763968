import Vue from 'vue'

// Initial state
const initialState = () => ({
  sindicatos: [],
  empresas: [],
  sindicato: {},
  sindicatoAtual: null,
  empresaAtual: null
})

// State object
const state = initialState()

// Getters
const getters = {
  obterListaSindicatos(state) {
    return state.sindicatos;
  },

  getTotalSindicatos(state) {
    return state.sindicatos.length
  },

  obterSindicatos(state) {
    return state.sindicatos.map(s => ({
      desc: s.nome,
      value: s.id
    }))
  },
  obterListaEmpresas(state) {
    return state.empresas;
  },
  obterEmpresas(state) {
    return state.empresas.map(e => ({
      desc: e.nome,
      value: e.id
    }))
  },
  obterIdEmpresa(state) {
    return state.empresaAtual
  },
  obterIdSindicato(state) {
    return state.sindicatoAtual
  }
}

// Actions
const actions = {
  clearState({
    commit
  }) {
    commit('RESET')
  },

  listarSindicatos({
    commit
  }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient()
      const url = `/v1/sindicatos/listar`;
      const resposta = await api.send(url, 'GET', null, undefined, false);
      if ([200, 201, 404].includes(resposta.status)) {
        commit('setSindicatos', resposta.data.dados)
        resolve({
          status: resposta.status,
          msg: 'Lista de sindicatos recuperada',
          dados: resposta.data.dados
        })
      } else {
        commit('setSindicatos', [])
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        })
      }
    })
  },

  incluirSindicato({
    commit
  }, {
    nome,
    empresas
  }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient()
      const url = `/v1/sindicatos`
      const resposta = await api.send(url, 'POST', {
        nome,
        empresas
      })
      if (resposta.status === 201) {
        resolve({
          status: resposta.status,
          msg: 'Sindicato registrado com sucesso'
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        })
      }
    })
  },

  listarEmpresas({
    commit
  }, id) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient()
      const url = `/v1/sindicatos/obter/${id}`;
      const resposta = await api.send(url, 'GET', null, undefined, false);
      if ([200, 201].includes(resposta.status)) {
        commit('setEmpresas', resposta.data.dados.empresas)
        resolve({
          status: resposta.status,
          msg: 'Empresas pertencentes a esse sindicato foram recuperada'
        })
      } else {
        commit('setEmpresas', [])
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        })
      }
    })
  },

  obterSindicato({
    commit
  }, {
    id,
    cache = true
  }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient();
      const url = `/v1/sindicatos/obter/${id}`;
      const resposta = await api.send(url, 'GET', null, undefined, cache);

      if (resposta.status == 200) {
        commit('setSindicato', resposta.data.dados);
        resolve({
          status: resposta.status,
          msg: "Sindicato recuperado",
          dados: resposta.data.dados,
        });
      } else {
        commit('setSindicato', {});
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem ||
            "Ocorreu um erro desconhecido durante sua requisição",
        });
      }
    });
  },

  editarSindicato(_, {
    id,
    nome,
    empresas
  }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient()
      const url = `/v1/sindicatos`
      const resposta = await api.send(url, 'PUT', {
        id,
        nome,
        empresas
      });

      if (resposta.status === 200) {
        resolve({
          status: resposta.status,
          msg: 'Sindicato editado com sucesso'
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        })
      }

    })
  },

  deletarSindicato(_, id) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/v1/sindicatos/${id}`
      const resposta = await api.send(url, 'DELETE');

      if (resposta.status == 200) {
        resolve({
          status: resposta.status,
          msg: 'Sindicato deletado com sucesso'
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        });
      }
    });
  }

}

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  setSindicatos(state, data) {
    state.sindicatos = data
  },
  setSindicato(state, data) {
    state.sindicato = data
  },
  setEmpresas(state, data) {
    state.empresas = data
  },
  setDadosNavegacao(state, data) {
    state.sindicatoAtual = data.sindicato
    state.empresaAtual = data.empresa
  }
}

// Module exports
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}