import Vue from 'vue';

// Initial state
const initialState = () => ({
  relatorio: {},
});

// State object
const state = initialState();

// Getters
const getters = {
  getRelatorio(state) {
    return state.relatorio;
  },
};

// Actions
const actions = {
  clearState({ commit }) {
    commit('RESET');
  },

  obterRelatorio({ commit }, { assembleia, sindicato, proposta }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/v1/cedulas/relatorio`;

      const requestBody = {
        assembleia,
        proposta,
      };

      if (sindicato) {
        requestBody.sindicato = sindicato;
      }

      const resposta = await api.send(url, 'POST', requestBody);

      if (resposta.status == 200) {
        commit('setRelatorio', resposta.data.dados);
        resolve({
          status: resposta.status,
          msg: 'Relatório obtido com sucesso',
        });
      } else {
        commit('setRelatorio', {});
        resolve({
          status: resposta.status,
          msg:
            resposta.data.status.mensagem || 'Ocorreu um erro desconhecido durante sua requisção',
        });
      }
    });
  },
};

// Mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  },

  setRelatorio(state, data) {
    state.relatorio = data;
  },
};

// Module exports
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
