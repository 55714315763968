import Vue from "vue";

// Inital State
const initialState = () => ({
  funcionarios: [],
  funcionario: {},
  locaisTrabalho: [],
});

// State object
const state = initialState();

// Getters
const getters = {
  getFuncionarios(state) {
    return state.funcionarios;
  },

  getFuncionario(state) {
    return state.funcionario;
  },

  getFuncionariosToSelectInput(state) {
    return state.funcionarios.map(funcionario => ({
      desc: funcionario.nome,
      value: funcionario.id
    }))
  },

  getLocaisTrabalhoToSelectInput(state) {
    return state.locaisTrabalho.map((localDeTrabalho) => ({
      desc: localDeTrabalho.nome,
      value: localDeTrabalho.id,
    }));
  },
};

// Actions
const actions = {
  clearState({
    commit
  }) {
    commit("RESET");
  },

  listarFuncionarios({
    commit
  }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient();
      const url = "/v1/funcionarios/listar";
      const resposta = await api.send(url, "POST", null, undefined, false);

      if (resposta.status == 200) {
        commit("setFuncionarios", resposta.data.dados.funcionarios);
        resolve({
          status: resposta.status,
          msg: "Lista de funcionários recuperada",
          dados: resposta.data.dados,
        });
      } else {
        commit("setFuncionarios", []);
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem ||
            "Ocorreu um erro desconhecido durante sua requisição.",
        });
      }
    });
  },

  listarLocaisDeTrabalho({
    commit
  }, idEmpresa) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient();
      const url = "/v1/locaisTrabalho/listar";
      const resposta = await api.send(
        url,
        "POST",
        idEmpresa ? {
          empresa: idEmpresa,
        } :
        null,
        undefined,
        false
      );

      if (resposta.status == 200) {
        commit("setLocaisTrabalho", resposta.data.dados);
        resolve({
          status: resposta.status,
          msg: "Lista de locais de trabalho recuperada",
          dados: resposta.data.dados,
        });
      } else {
        commit("setLocaisTrabalho", []);
        resolve({
          status: resposta.status || 500,
          msg: resposta.data.mensagem ||
            "Ocorreu um erro desconhecido durante sua requisição.",
        });
      }
    });
  },

  incluirFuncionario({
    commit
  }, {
    nome,
    cpf,
    sindicato,
    empresa,
    localTrabalho,
    associado,
    celular,
    email,
    matricula,
  }) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient();
      const url = "/v1/funcionarios";

      const resposta = await api.send(url, "POST", {
        nome,
        cpf,
        sindicato: sindicato.id,
        empresa: empresa.id,
        localTrabalho: localTrabalho.id,
        celular,
        email,
        associado,
        matricula,
      });

      if (resposta.status === 201) {
        resolve({
          status: resposta.status,
          msg: "Funcionário registrado com sucesso",
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem ||
            "Ocorreu um erro desconhecido durante sua requisição.",
        });
      }
    });
  },

  editarFuncionario(_, {
    id,
    nome,
    cpf,
    sindicato,
    empresa,
    localTrabalho,
    associado,
    celular,
    email,
    matricula,
  }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/v1/funcionarios`;
      const resposta = await api.send(url, 'PUT', {
        id,
        nome,
        cpf,
        sindicato: sindicato.id,
        empresa: empresa.id,
        localTrabalho: localTrabalho.id,
        associado,
        celular,
        email,
        matricula
      });

      if (resposta.status == 200) {
        resolve({
          status: resposta.status,
          msg: 'Funcionário editado com sucesso'
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        });
      }
    });
  },

  obterFuncionario({
    commit
  }, {
    id
  }) {
    return new Promise(async resolve => {
      const api = new Vue.prototype.$httpClient();
      const url = `/v1/funcionarios/obter/${id}`;
      const resposta = await api.send(url, 'GET');

      if (resposta.status == 200) {
        commit('setFuncionario', resposta.data.dados);
        resolve({
          status: resposta.status,
          msg: 'Funcionário recuperado com sucesso',
          dados: resposta.data.dados
        });
      } else {
        commit('setFuncionario', {});
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem || 'Ocorreu um erro desconhecido durante sua requisição.'
        });
      }
    });
  },

  deletarFuncionario(_, idFuncionario) {
    return new Promise(async (resolve) => {
      const api = new Vue.prototype.$httpClient();
      const url = `/v1/funcionarios/${idFuncionario}`;

      const resposta = await api.send(url, "DELETE");

      if (resposta.status == 200) {
        resolve({
          status: resposta.status,
          msg: "Funcionário deletado com sucesso",
        });
      } else {
        resolve({
          status: resposta.status,
          msg: resposta.data.mensagem ||
            "Ocorreu um erro desconhecido durante sua requisição",
        });
      }
    });
  },
};

const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },

  setFuncionarios(state, data) {
    state.funcionarios = data;
  },

  setFuncionario(state, data) {
    state.funcionario = data;
  },

  setLocaisTrabalho(state, data) {
    state.locaisTrabalho = data;
  },
};

// Module exports
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};