<template>
  <div class="w-100 bg-primary rounded p-3 card-container">
    <div class="row">
      <div class="col-12">
        <p class="text-white text-uppercase m-0">
          <strong>Assembleias Ativas</strong>
        </p>
        <hr class="w-100 mt-1"/>
      </div>
      <div 
        v-for="(p, i) in assembleiasAtivas"
        :key="i"
        :class="{'d-none': i>=5}"
        class="pautas-item cursor-pointer col-12 d-flex justify-content-between align-items-center mb-1"
        @click="navigateToAssembleia(p.id)"
      >
        <a style="flex-grow:1;" class="m-0 text-white">{{ p.nome }}</a>
        <div 
          class="d-flex justify-content-end align-items-center"
          style="max-width:200px;"
        >
          <span class="link-pauta text-white">
            <i class="fas fa-arrow-circle-right"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'assembleias-disponiveis-card',
  computed: {
    ...mapGetters({
      assembleiasAtivas: 'assembleias/getAssembleiasDisponiveis'
    })
  },
  methods: {
    ...mapActions({
      loadAssembleiasDisponiveis: "assembleias/carregarAssembleias"
    }),
    navigateToAssembleia(id) {
      this.$router.push({ 
        name: `assembleiasParam`, 
        params: { pathMatch: id }
      })
    }
  },
  beforeMount() {
    this.loadAssembleiasDisponiveis({ disponiveis: true }).then((response) => {
      if (![200, 201, 404].includes(response.status)) {
        this.$swal({
          type: response.status == 500 ? "error" : "warning",
          title: "Ops...",
          text: response.msg,
        });
      }
    });
  }
}
</script>

<style>
.pautas-item:hover  a {
  text-decoration: underline!important;
}
</style>