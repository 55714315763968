<template>
  <div id="navHeader">
    <h3 class="m-0 text-uppercase text-primary">{{
      this.$route.meta.title
      ? this.$route.meta.title
      : ''
    }}</h3>
    <p class="m-0"><small>{{
      this.$route.meta.subtitle
      ? this.$route.meta.subtitle
      : ''
    }}</small></p>
    <span @click="logout" class="logout text-primary">
      <i class="fas fa-sign-out-alt"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'navigation-header',
  methods: {
    logout() {
      localStorage.clear()
      window.location.reload()
    }
  }
}
</script>